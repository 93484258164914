import {createInertiaApp} from '@inertiajs/react';
import {createElement} from 'react';
import {createRoot} from 'react-dom/client';

createInertiaApp(
  {
    resolve: (name) => {
      if (!name) {
        return false;
      }

      const pages = import.meta.glob('./pages/**/*.jsx');
      return pages[`./pages/${name}.jsx`]();
    },
    setup({el, App, props}) {
      if(props?.initialComponent) {
        createRoot(el).render(
          createElement(App, props)
        );
      }
    },
  }
);
